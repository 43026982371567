$textColor: #212529;
$primaryColor: #f5df4e;
$bgColor: #F8F9FA;
$bgSecondColor: #fff;
$accentColor: #f0d900;

$titleFontFamily: 'Poppins', sans-serif;
$textFontFamily: 'Poppins', sans-serif;

$tempBorder: 1px solid lightgray;

//for media query
$xs: 350px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1536px;
$xxl: 1800px;
