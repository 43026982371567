@import '../../../../common/styles/sass/variables';
@import '../../../../common/styles/sass/mixins';


.burgerNav {
    width: 100%;
    height: 100%;
    //border: 1px solid blue;

    .burgerNavItems {
        position: absolute;
        background-color: $primaryColor;
        right: 0;
        top: 0;
        left: 0;
        height: 0;
        @include flex(column, space-between, center, nowrap);
        padding: 20px;
        transform: translate(0, -150px);
        transition: 0.5s;

        a {
            color: $textColor;
            text-decoration: none;
            font-weight: 600;
            cursor: pointer;

            &:hover {
                color: $bgSecondColor;
            }
        }


        .active {
            color: $bgSecondColor;
        }
    }

    .show {
        transform: translate(0, 0);
        height: 150px;

    }

    .burgerBtn {
        @include flex(row, center, center, nowrap);
        width: 25px;
        height: 25px;
        position: absolute;
        top: 29px;
        right: 30px;
        cursor: pointer;
    }

    @media screen and (min-width: 601px) {
        display: none;

    }
}