@use '../../../../common/styles/sass/mixins';
@import '../../../../common/styles/sass/variables';

.project {
    width: 450px;
    @include mixins.flex(column, flex-start, stretch, nowrap);

    .buttonContainer {
        @include mixins.flex($direction: column, $justifyContent: center, $alignItems: center);
        height: 300px;
        background-color: $bgColor;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;

        &::before {
            position: absolute;
            content: '';
            background-color: #212529;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0.4;
        }

        .button {
            width: auto;
            padding: 0 10px;
        }

        .secondButton {
            margin-top: 10px;
        }
    }

    .projectInfo {
        padding: 2.25rem 1.25rem;

        .projectTitle {
            @include mixins.text($titleFontFamily, 18px, 1.8);
        }

        .description {
            @include mixins.text($textFontFamily, 16px, 1.8);
            font-weight: 300;
        }
    }
}

@media only screen and (max-width: 1800px) { // 1536px - 1800px
    .project {
        width: 400px;
        .image {
            .viewBtn {
            }
        }

        .projectInfo {
            .projectTitle {
            }

            .description {
            }
        }
    }
}

@media only screen and (max-width: 1536px) { // 1200px - 1536px
    .project {
        width: 315px;

        .image {
            .viewBtn {
            }
        }

        .projectInfo {
            .projectTitle {
                @include mixins.text($titleFontFamily, 18px, 1);
            }

            .description {
                @include mixins.text($textFontFamily, 16px, 1.5);
            }
        }
    }
}

@media only screen and (max-width: 1200px) { // 600px - 1200px
    .project {
        //width: 200px;

        .image {
            width: 300px;
            height: 200px;
            .viewBtn {
            }
        }

        .projectInfo {
            .projectTitle {
            }

            .description {
            }
        }
    }
}

@media only screen and (max-width: 600px) { // 350px - 600px
    .project {
        width: 100%;
        @include mixins.flex(column, center, center, nowrap);


        .image {
            width: 200px;
            height: 150px;

            .viewBtn {
            }
        }

        .projectInfo {
            @include mixins.flex(column, center, center, nowrap);

            .projectTitle {
            }

            .description {
            }
        }
    }
}