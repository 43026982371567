.cv {
    max-height: 100vh;
    background: #3A393E;
    display: flex;
    flex-direction: column;
    align-items: center;

    .iframe {
        width: 100%;
        height: 100vh;
    }

    .buttonAdditional {
        width: 120px;
        height: 30px;
        margin: 5px;
    }
}



